import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 198 199" {...props}>
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M98.552 198.607c-29.49-.022-53.246-7.027-69.844-19.609C11.91 166.265 3 148.195 3 127.748c0-19.701 8.891-33.907 18.952-43.51 7.884-7.525 16.584-12.344 22.642-15.117-1.37-4.162-3.079-9.61-4.608-15.238-2.046-7.53-4.053-16.366-4.053-22.84 0-7.663 1.687-15.36 6.238-21.34C46.98 3.385 54.218 0 62.926 0c6.806 0 12.584 2.499 17.108 6.81 4.324 4.12 7.202 9.593 9.189 15.298 3.491 10.024 4.85 22.618 5.232 35.187h8.341c.382-12.569 1.741-25.163 5.233-35.187 1.987-5.705 4.865-11.177 9.189-15.298C121.741 2.5 127.519 0 134.325 0c8.708 0 15.947 3.385 20.755 9.703 4.551 5.98 6.239 13.677 6.239 21.34 0 6.474-2.007 15.31-4.054 22.84-1.529 5.628-3.238 11.076-4.608 15.238 6.058 2.773 14.759 7.592 22.643 15.118 10.06 9.602 18.952 23.808 18.952 43.509 0 20.447-8.911 38.517-25.708 51.25-16.598 12.582-40.354 19.587-69.844 19.609h-.148z"
    //     fill="#633001"
    //   />
    //   <path
    //     d="M62.926 7.288c-12.754 0-18.626 9.516-18.626 22.675 0 10.46 6.822 31.408 9.621 39.563.63 1.835-.36 3.844-2.164 4.555-10.222 4.031-40.39 18.789-40.39 52.588 0 35.603 30.658 62.448 87.191 62.49h.135c56.534-.042 87.19-26.887 87.19-62.49 0-33.799-30.167-48.557-40.389-52.588-1.804-.71-2.794-2.72-2.164-4.555 2.799-8.154 9.621-29.103 9.621-39.563 0-13.16-5.871-22.675-18.626-22.675-18.36 0-22.936 26.007-23.263 53.92-.022 1.863-1.528 3.375-3.392 3.375H89.58c-1.863 0-3.37-1.512-3.391-3.375-.326-27.913-4.903-53.92-23.263-53.92z"
    //     fill="#D1884F"
    //   />
    //   <path
    //     d="M98.693 177.755c-41.538 0-87.253-22.235-87.325-51.018v.134c0 35.632 30.705 62.491 87.325 62.491 56.62 0 87.325-26.859 87.325-62.491v-.134c-.072 28.783-45.787 51.018-87.325 51.018z"
    //     fill="#FEDC90"
    //   />
    //   <path
    //     className="eye"
    //     d="M75.614 117.896c0 9.718-4.593 14.779-10.258 14.779-5.665 0-10.258-5.061-10.258-14.779s4.593-14.779 10.258-14.779c5.665 0 10.258 5.061 10.258 14.779zM142.288 117.896c0 9.718-4.592 14.779-10.257 14.779-5.666 0-10.258-5.061-10.258-14.779s4.592-14.779 10.258-14.779c5.665 0 10.257 5.061 10.257 14.779z"
    //     fill="#633001"
    //   />
    // </Svg>
    <Svg version="1.1"  {...props} id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 50 50" enable-background="new 0 0 50 50" xmlSpace="preserve">  <image id="image0" width="50" height="50" x="0" y="0"
    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAyCAYAAADx/eOPAAAAIGNIUk0AAHomAACAhAAA+gAAAIDo
AAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCa
nBgAAAwmSURBVGje7Zl5VFRXtoe/e4uiiiomlVlBQEyVQtDXMWKSB0S0NWq3HbU1L1Gz1Gi3ppca
M3QSh2jSeUbbSJzStlG0ndp0tDWuPETkGQUncHqJ0SiJA4rKJHNBATXs9weDkBAn7LZ7LX9r3bXq
7Dpnn/2ds++5554LD/VQ/3Ap/6R+goEeQA7QHagDPv93gokd+4RXokaj+vWKNvk+NWK6W8nFY9hd
PLh2/humLf78XUuNc94/sP82K2BMH+89rw0K+H7V73qLWItFik6L1FmkUUezMuXbr4/JZwsnCDDt
QQfcqrolWzu7dAj8Lj19f33UTqeIiFgs9SA7d/xdREQuncqQxe/9XqS6SGaOjnECcfcrBvV+OJkn
ouYlvZFmL87rarFU1RsVhaPpKWRmZgJQfCaVnJwcQh+NJSbQwqatyfz3x39TfhUTuJ76e+pfQyHL
9n+i6o0CyMSJE5tSquR0sgwf+GR9obpYlk+Nl9LSUpHK6/LJ5EflxIkTUpz+kfh7uex40AwAROyq
7O7Zd5QATVdiYqJcu3atPq22/15WLJglIiJVmStk8uhfiu3Gebmw4UWZOSxMQn204q5Xrz1oDgDC
Psnaiqq2gAEkPCRInIc/EGfmAvnrlHDJSpoiWYsSJDpYL8/HeAkg3TvqBZA3B/kWPGgOuqVWRgW+
saoFhEGnSqiPq6gK8s5QPwnpoBWDqyozh/gKIDHhBvF218nj3TrJseW/lsPJ6+WXT/8sD2jX1nja
tAA4FYbZ8q+0sL33rB9J74zGKXDmeg1Xim0Y9QpXSuoAaOcXzJcZR5j+9vtE9BrAE4NfZPWnuwLc
3d1fe6AwiOLR/LGrKDA8oScJU9fi7+/PvrP1K5vRVcOmI+UAODyCoa4Sa84BdD6dAfD39ycsLKx/
W2Fc2tJYwak030T0MxsJe+YtzmefZUSMH9W1PvTsE4dv5QlGJx5lRGxXjD5ebNyyjcd6xZH8VQW9
XHIA8FMKnQ8UBrAjN2MY1a87hMQRVnaZj3eearKPGzeODyYZeCtxM7gHkZGRQUZGBhEREaSkpJC8
cTGLh7sX9DzVtnWgbTCK6qq4uDYVzU8OJy8vn8DA0CbbuXPnCO2g4a2FKeCiByAuLo64uDgOHjzI
d8kLSHpOe8XfixltHNi23TOKiFXjeXMRWrYti2O717eoU1ZSxLzFSU0gACLCRx8u4nTSWGb3dZz0
9yJeGX8up60wbZoZAbvq0b6pvG3755RXVPDIE89iNptJTk5m0qRJJCQkEB8fj4hw8uRJ8r4/yZt9
ion5T+MKVVczSxlzvqKtIG2GcapKjS6kG1C/IplMJgqLipk1axZLly4lUFOAzlbMU4GVLFs4l5KS
G7w/zI9nh3lWeBkMb2peOv3n+wFxX2A0Dq6JfwiKqqLT6UhPT2/xf6dOE/g8qZz9l1RWJL1K/4S+
6F2UTe2MmpnKhNO5P3D3C6AfsBU4fD8hfygtYAZeAGYBq4CtLu38vjJExwogWq1WrFar/EgOm2Sm
p4iIyMSJEwX4ohX//TSqcoWbO4kzwLuAz/0I3gsYCawFLgJVjR0pLlrR+oeI8bF+4jVwrLQfNUN0
4VECSHZ2ttxKOTk5otVqBdgM/BcNWxj5JGqYIymy/NDMcBna06P59qgImHunQf/otTk6VYyVFw4M
K/vfrYnlu9f56rpEY4iOxy0yBtdOXXHx9itU9IbrCpxHoQSHc2Re4pR25Xs2kZGRQWxs7C07fP31
19m1ZQUDozxI+aayJjuvNhNYI+vNux112qGK6nzn6ys1oaP+nMv5grrGZhepT8Gcu5oSc4o11LS7
YoMp1eI0p1adMO+pXmxKqRwZmWYNQaQRXgP8DJhujBl0xbVjhACyceNGuZ1yc3NFr9fLl2+EimNN
pFxa+Ij8YZi/dGrvmgP8BsCeFDnOsTry2tyhvs1n6Srgf2+JdjPwRumA54G9qtG7tv3IGRK8aLeE
rTkpnv1fEECmTZt2WxgRkTlz5ogpQCcfjwmSP44KkL9NCZaaVZEyd6ifqKpyAvCRDdFGe1LU9vkj
/JsDpdwbzE21BxKBKr3pMek4b0t+2PrTn3omPHfIvc+Q6uCF/yOdVxwQQKKiou4I5vr16+Lm5iZf
TOuc7VgTWVSwxCwrXwySuUP9ZNeMzhLk7XoR0APY1kQuiDcZG2HqAM97gVCoPz2xqkZPaT/ylaOd
V2W9oPXpuFhv6mX3++3Cg+Y0y8BuqVVTIrblNo3ehQsX7gjo1VdfbRrpunXd+jhWR663r460vTXY
R/p3NwowD0ASn3BbMTrwbLPZCb1bEAOws6Fxqd+M5ZOBKH333mc8+z8vHrHDftVYMXJ3dW9TqkVc
2gcIIIsWLbojmMLCQvH29hagb1NmrzOHOpMid80Y0EGAzEb7wCj3yYCE+bqKrDaF3TFF6I5Sb11w
19RmI9G3wy9e6o2iyQpdeeQosK95/Yhdlb6mVIsYesYJID169BCHw9EUdH5+/k8CvfLKKwLs/WEM
OX80bQMONDNtAWTpC4GXZd5d7Cf9f5e4txlIhnmfNdQr4bkqfZfovV13Fojq2eF7frCkP7LHku81
YExTqh05cqQp4LVr1/4kTHp6etOANff3VLihVxd/XeOgzQLkw1EBYlvdfR7QEdgILAFeBrwb27Wg
fCStalL1V/sSmpl2SK19SW3eRYOzrqZM1RvxHT83AniveTsVslWDR1N58eLFTb+tVitffNHaQ7/+
VSA+Ph5gdjNzh7xK+6frxnd0BT7qGaJ/P+31zswY0P5D7aRvNwG7gTHAdCAEKGvVuWmPJdn4+IDm
S+EUU6ql3HvIhFqgJGJz9hpTqkWC5mx26CP+YwMNN6M5teIvAdOWNLVTFKXpqOnw4cNiMpl+cnbm
z5/f2O5pYNDTJsP5lxPay4henrJ2QpDYVncvuvKh+TXqtzeVgKju3uIaYp53yxQzpVYe8hk7qznM
TvMeS3r42q8vNzhKC9+Q/Y451VIevumseA8e79B4eK/z/c2CYx3nbG5xSjN16lQRESkqKhJFUWTl
ypWtwmRlZdWf6riqpSN7ecmiUQFy6O1wcayJKj0+N2JrF1/tMqAYEFVvFK8BY4uD3/1sSmvxt8h9
0x7LGmdl2UuFK99A7PbjFfu3/sm0uzIHRfmyYv/WM3kfjA8Fijxih7/bcfZf+grqi7aCy1Tu2woZ
m+nCNTx0KrklNnJK7GzYtAVPT08mT55MWVkZy5cvp7byBpaiy3RtV0fJhaPYKvOI8HOlZ7Aeg6ta
euj76tzPjlcYN2WW3SivdvQGFLfuvXHvM6Ta2PuZ5cYuvf905ufKldvCRO2t6WK3O5YokCuqHDz3
c/e/AphTLG+Lyvy63O+OX509vJstP8cIbG83aNwJ/+nLuqJofi2Ie9XJL9Fd+4ZHyzPxLfwaX42V
wHYueLlpaGfU4OehweiqUmuHGxY7+eV2LhfbHJdu1El2QW3RsUtWo92peGqDIjD0jEVv6oU+PPor
XZcenzqc6ubzgw1Xb5VZd/x9xpRWOQqnuhDEt3zPRtfizQs1tvwcFbjgGhh+1Gvobwvaj3hZj1N5
EoVogLrc77AVXMZeUijYrIqjsgxnnRUcdlBUVJ0bqtELxc0dReOCrnM3tEFdrqoGt/2KKAdAPXFu
oPHEncZ4Vx+bTDuLghSd7nlBfQ2FwOpTB6hI20zV8TTsJQUA5cD/uXj7Frv3GWJx7Rhx0T1uhEUb
GGJUEG8FMTiduCgKDhGsAqWqouaLQr7iUIpctM6Lp/u73/MRzT19Oeu2uyjQoWjjFFzGgTwjDgfW
M0eoy/mWqmNp1F44ha34evMm5cAFoPFdX4BaYA3w93sN/r7ANFfoDvHWu1fFiFMGI0ofFB4HFHtp
AY6yIuwlBdiuX8BeWojTUorDUo7Yaqk5eyzDVnR1KbD9XwbmR3DrLukNgYGdHHZbMFo1TFHojBCA
gi+IEUGLgiKos7MHGA7d7/4f6t9R7jTbyP2E9A3XreR9B36MdxBPwK36ut122kTLjVxrjmqArs3K
rb2nR1F/4tMor1bqGIBHb+PHraG/e4LxoP687G5kacVW0eCrUeWt1Amn5dez1vzcUprb/O+k/vnQ
KHsrddwbrhsNZVsrdQIBK1Byi77KGtqW38KPwi1m5qEe6p+g/wdbSqSIw7xhUgAAACV0RVh0ZGF0
ZTpjcmVhdGUAMjAyNC0wOS0yOVQwNjo1Mjo1NCswMDowMO7Rf+MAAAAldEVYdGRhdGU6bW9kaWZ5
ADIwMjQtMDktMjlUMDY6NTI6NTQrMDA6MDCfjMdfAAAAKHRFWHRkYXRlOnRpbWVzdGFtcAAyMDI0
LTA5LTI5VDA2OjUyOjU0KzAwOjAwyJnmgAAAAABJRU5ErkJggg==" />
</Svg>
  );
};

export default Icon;
